import Lottie from "lottie-react"; 
import Page from "../../comman/page";
import animationData from "../../assets/animations/animation_lo5bk2b2.json";
import { useEffect, useState } from "react";
import QuestionService from "../../services/QuestionService";
import Markdown from "../../utils/markdown";
const SuccessPage = (props) => { 
  const [pageContent, setPageContent] = useState();
  const getPageContent = async () => {
    const res = await QuestionService.getPageContent();
    const successPageContent = res?.data?.find(
      (x) => x?.attributes?.PageTitle === "success"
    );
    setPageContent(successPageContent?.attributes);
  }; 
  useEffect(() => {
    getPageContent();
  }, []);
  return (<>
  
    <Page
      children={
        <div className="d-flex justify-content-center user-not-found success_loader">
          <Lottie animationData={animationData} loop={true} autoplay={true} />
          <div className="row mt-5">
            <div className=" col-md-12 intro">
              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>{" "}
                {pageContent && <p>{Markdown(pageContent?.Content)}</p> }
                <div className="d-flex cus-text-center"></div>
              </div>
            </div>
          </div>
        </div>
      }
    /></>
  );
};
export default SuccessPage;
