import axiosHttp from "./interceptor";

const GetAllQuestions = (id) => {
  try {
    return axiosHttp
      .get(
        `candidates?[filters][InvitationCode][$eq]=${id}&populate[0]=interviews.Questions`
      )
      .then((res) => {
        return res?.data?.data[0]?.attributes?.interviews?.data?.attributes
          ?.Questions;
      });
  } catch (error) {
    return [];
  }
};

const checkInvitationCode = (id) => {
  try {
    return axiosHttp
      .get(
        `candidates?[filters][InvitationCode][$eq]=${id}&populate=interviews.Questions&populate=interviews.Instructions&populate=interviews.Interviewer&populate=company.Logo`
      )
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return error;
  }
};

const InterviewResult = (data) => {
  try {
    return axiosHttp.post(
      `interview-results`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    return error;
  }
};
const Create = (data) => {
  const formData = new FormData();
  formData.append("files", data);
  try {
    return axiosHttp.post(`upload/`, formData).then((uploadResponse) => {
      return uploadResponse?.data?.[0];
    });
  } catch (error) {
    return error;
  }
};
const getCandidate = (id) => {
  try {
    return axiosHttp.get(`candidates/${id}`).then((res) => {
      return res?.data?.data?.attributes?.Status;
    });
  } catch (error) {
    return error;
  }
};
const UpdateCandidateInterviewStatus = async (id, Status) => {
  try {
    await getCandidate(id);

    return axiosHttp
      .put(`/candidates/${id}`, { data: { Status } })
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return error;
  }
};

const GetInterviewResult = (candidate, interviewer) => {
  axiosHttp
    .get(
      `interview-results?populate[0]=deep&[filters][candidate][id][$eq]=${candidate.id}`,
      {}
    )
    .then((res) => {
      SendEmail(res, interviewer);
    });
};

const SendEmail = (res, interviewer) => {
  axiosHttp
    .post(`email-send`, {
      data: res,
      email: interviewer.attributes.NotificationEmail,
    })
    .then((response) => {})
    .catch((error) => {});
};

const getPageContent = async () => {
  return axiosHttp.get(`page-contents`).then((res) => {
    return res?.data;
  });
};
const sendNotificationOnEmail = (id) => {
  try {
    return axiosHttp.put(
      `/candidate/result/email-send/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    return error;
  }
};
const QuestionService = {
  GetAllQuestions,
  Create,
  InterviewResult,
  SendEmail,
  GetInterviewResult,
  checkInvitationCode,
  UpdateCandidateInterviewStatus,
  getPageContent,
  sendNotificationOnEmail,
};

export default QuestionService;
