import { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css"; 
import "bootstrap/dist/css/bootstrap.min.css"; 
import ErrorPage from "./pages/ErrorPage";
import WelcomePage from "./pages/WelcomePage";
import InterviewPage from "./pages/InterviewPage";
import SuccessPage from "./pages/SuccessPage"; 
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  window.addEventListener("contextmenu", (e) => e.preventDefault());
  window.addEventListener("keydown", (e) => {
    if (e.keyCode == 123) e.preventDefault();
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) e.preventDefault();
    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) e.preventDefault();
  });
  // useEffect(() => {
  //   window.addEventListener("beforeunload", function (e) {
  //     e.preventDefault();
  //     e.returnValue =
  //       "Leaving this page will invalidate the test. Are you sure you want to leave?";
  //   });

  //   return () => {
  //     window.removeEventListener("beforeunload", function (e) {
  //       e.preventDefault();
  //       e.returnValue =
  //         "Leaving this page will invalidate the test. Are you sure you want to leave?";
  //     });
  //   };
  // }, []);
  return (
    <div>
      <BrowserRouter>
        <Routes> 
          <Route path="/:id" element={<WelcomePage />} /> 
          <Route path="/test/:id" element={<InterviewPage />} />
          <Route
            path="success"
            element={<SuccessPage data={landingPageData.Success} />}
          />
          <Route path="*" element={<ErrorPage children={"User Not Found"} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
