import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import VideoRecorder from "react-video-recorder";
import QuestionService from "../../services/QuestionService.js";
import { errorStatus } from "../WelcomePage/index.jsx";
import Loader from "../../comman/loader.jsx";
import { orionLogo } from "../../assets/index.js";

const InterviewPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = React.useRef();
  const [isRecording, setIsRecording] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestion] = useState();
  const [videoAnswers, setVideoAnswers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [questionsAndAnswer, setQuestionsAndAnswer] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [userId, setUserId] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [animationClass, setAnimationClass] = useState("slide-in");
  const storedIndex = parseInt(localStorage.getItem("questionIndex"));

  const allQuestions = async (id) => {
    const response = await QuestionService.GetAllQuestions(id);
    if (response?.length > 0) {
      setQuestion(response);
    } else {
      navigate("/", { state: { Status: errorStatus[3] } });
    }
  };

  const saveVideoAnswer = async (video, title) => {
    const videoRespone = await QuestionService.Create(video);
    if (localStorage?.getItem("questionqueue") == null) {
      localStorage.setItem(
        "questionqueue",
        JSON.stringify({ candidate: location.state.id, Answers: [] })
      );
    }

    if (videoRespone) {
      const getVideoIds = JSON.parse(localStorage.getItem("questionqueue"));
      getVideoIds.Answers.push({ Question: title, Answer: videoRespone?.id });

      localStorage.setItem("questionqueue", JSON.stringify(getVideoIds));
      const answer = { Question: title, Answer: videoRespone?.id };
      videoAnswers.push(answer);
      return answer;
    }
  };

  const uploadAnswerVideo = async (questionsAndAnswer) => {
    for (const record of questionsAndAnswer) {
      await saveVideoAnswer(record.videoBlob, record.questionTitle);
    }
  };

  const slideQuestion = () => {
    setAnimationClass("slide-out");
    setTimeout(() => {
      setAnimationClass("slide-in");
    }, 500);
  };

  const handleNextQuestion = async (videoBlob, Title) => {
    slideQuestion();

    // Set loader to true only when storedIndex is 3
    const shouldShowLoader = storedIndex === 3;
    setLoader(shouldShowLoader);

    const answer = [
      {
        questionTitle: questions?.[storedIndex]?.Title,
        videoBlob,
      },
    ];
    if (storedIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      localStorage.setItem("questionIndex", 1 + storedIndex);
      await uploadAnswerVideo(answer);
      setLoader(false);
    } else {
      setIsRecording(false);

      uploadAnswerVideo(answer).then((res) => {
        if (storedIndex === questions.length - 1) {
          saveInterviewResult();
          QuestionService.UpdateCandidateInterviewStatus(
            location?.state?.id,
            "complete"
          );
          localStorage.removeItem("questionIndex");
          localStorage.removeItem("questionqueue");

          QuestionService.sendNotificationOnEmail(userId);
          setLoader(false);
          navigate("/success");
        }
        setLoader(false);
        setCurrentQuestionIndex((prev) => prev + 1);
      });
    }
  };

  const saveInterviewResult = async () => {
    // const payload = {
    //   candidate: location?.state?.id,
    //   Answers: videoAnswers,
    // };
    const finalPayload = JSON.parse(localStorage.getItem("questionqueue"));
    await QuestionService.InterviewResult(finalPayload);
  };

  const handleRecordingComplete = async (videoBlob) => {
    const button =
      ref?.current?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]
        ?.childNodes?.[0];
    button.innerHTML = "NEXT";
    button.addEventListener("click", () => {
      if (storedIndex <= 3) {
        localStorage.setItem("questionIndex", 1 + storedIndex);
      }
      handleNextQuestion(videoBlob, questions?.[storedIndex]?.Title);
    });
  };

  const videoConstraints = {
    audio: true,
    video: {
      width: { ideal: 800 },
      height: { ideal: 600 },
    },
  };

  const checkAndUpdateCandidateInterviewStatus = async () => {
    const response = await QuestionService.checkInvitationCode(id);
    const { data } = response;
    if (
      location?.state?.attributes?.InvitationCode === id &&
      location?.state?.attributes?.Status !== "complete" &&
      data?.[0]?.attributes?.Status !== "complete" &&
      data?.[0]?.attributes?.Status !== "expired"
    ) {
      const userData = response?.data?.[0];
      setUserId(userData?.id);
      setCompanyData(userData?.attributes?.company);
      const statusResponse =
        await QuestionService.UpdateCandidateInterviewStatus(
          location?.state?.id,
          "inactive"
        );
      const { data } = statusResponse;
      if (data?.Status === "inactive") {
        slideQuestion();
        allQuestions(id);
        setIsRecording(true);
        setLoader(false);
      }
    } else {
      let status = "";
      if (location?.state?.attributes?.Status === "completed") {
        status = errorStatus[1];
      }

      if (location?.state?.attributes?.Status === "expired") {
        status = errorStatus[1];
      }
      navigate("/", { state: { Status: errorStatus[1] } });
    }
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(questions?.[storedIndex]?.Title);
    setUtterance(u);
    if (isPaused) {
      synth.resume();
    }
    setTimeout(() => {
      synth.speak(u);
    }, 1000);
    setIsPaused(true);
    return () => {
      synth.cancel();
    };
  }, [questions?.[storedIndex]?.Title]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    if (isPaused) {
      synth.resume();
    }
    synth.speak(utterance);
    setIsPaused(true);
  };

  useEffect(() => {
    if (!storedIndex) {
      localStorage.setItem("questionIndex", 0);
    }
    checkAndUpdateCandidateInterviewStatus();
    setIsRecording(true);
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div>
            <div id="step-1">
              <div className="container-fluid">
                {questions && (
                  <>
                    {!loader && (
                      <>
                        <div className={`content-container ${animationClass}`}>
                          <div className=" video-assessment-screen">
                            <div className="row row_wise">
                              <div className="col-md-6 px-0 left_sec">
                                <div className="Powered_logo">
                                  <div className="sm_text">
                                    <div className="orion_logo">
                                      <img
                                        src={
                                          companyData?.data?.attributes?.Logo
                                            ?.data?.attributes
                                            ? process.env.REACT_APP_VIDEO_URL +
                                              companyData?.data?.attributes
                                                ?.Logo?.data?.attributes?.url
                                            : orionLogo
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                {questions?.[storedIndex]?.Question?.data
                                  ?.attributes?.url ? (
                                  <video className="videos" controls>
                                    <source
                                      src={`${process.env.REACT_APP_VIDEO_URL}${questions?.[storedIndex]?.Question?.data?.attributes?.url}`}
                                      type={
                                        questions?.[storedIndex]?.Question?.data
                                          ?.attributes?.mime
                                      }
                                    />
                                  </video>
                                ) : (
                                  <div className="d-flex flex-wrap justify-content-center  mh100 position-relative fff">
                                    <div className="wrapper">
                                      <div className="video-main">
                                        <div className="promo-video">
                                          <div className="waves-block">
                                            <div className="waves wave-1"></div>
                                            <div className="waves wave-2"></div>
                                            <div className="waves wave-3"></div>
                                          </div>
                                        </div>
                                        <i
                                          onClick={handlePlay}
                                          className="fa fa-play"
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="typing-demo">
                                      <p>{questions?.[storedIndex]?.Title}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                ref={ref}
                                className="videoContainer col-md-6 px-0 webcam "
                              >
                                <VideoRecorder
                                  flipCamera={true}
                                  timeLimit={60000}
                                  isRecording={isRecording}
                                  onRecordingComplete={handleRecordingComplete}
                                  replayVideoAutoplayAndLoopOff={false}
                                  isOnInitially
                                  countdownTime={2000}
                                  constraints={videoConstraints}                                 
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InterviewPage;
