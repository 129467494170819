const Page = ({children}) => {
    return (
        <div>
            <section className="container-fluid home-bg px-0">
                <div>
                    <div className="container ">
                        <div className="row min-h100 justify-content-center align-items-center">
                           {children}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Page