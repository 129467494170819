import { useLocation } from "react-router-dom";
import Page from "../../comman/page";
import Lottie from "lottie-react";
import errorPageData from "../../assets/animations/errorPage.json";

const ErrorPage = ({ children }) => {
  const location = useLocation();
  children = location?.state?.Status || children;

  return (
    <Page
      children={
        <div className="d-flex justify-content-center user-not-found success_loader">
          <Lottie animationData={errorPageData} loop={true} autoplay={true} />
          <div className="error_text">{children}</div>
        </div>
      }
    />
  );
};
export default ErrorPage;
