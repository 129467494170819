import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionService from "../../services/QuestionService.js";
import ErrorPage from "../ErrorPage/index.jsx";
import Loader from "../../comman/loader.jsx";
import { orionLogo } from "../../assets/index.js";
export const errorStatus = {
  1: "Invitation Expired",
  2: "Interview Already Completed",
  3: "Please contact to HR",
};
const WelcomePage = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [interviewDetails, setInterviewDetails] = useState();
  const [expiredCode, setExpiredCode] = useState(0);
  const [loader, setLoader] = useState(true);
  const [companyData, setCompanyData] = useState();
  const checkInvitation = async (id) => {
    const response = await QuestionService.checkInvitationCode(id);
    if (response.data.length) {
      const userData = response?.data?.[0];
      const interviewDetails = userData?.attributes?.interviews?.data;
      if (!interviewDetails) {
        setExpiredCode(3);
      }
      if (userData?.attributes?.Status === "complete") {
        setExpiredCode(2);
      }
      if (userData?.attributes?.Status === "expired") {
        setExpiredCode(1);
      }
      // if (userData?.attributes?.Status === "inactive") {
      //   setExpiredCode(1);
      // }
      const currentDate = new Date();
      if (currentDate > new Date(userData?.attributes?.Expire_at)) {
        setExpiredCode(1);
        await QuestionService.UpdateCandidateInterviewStatus(
          userData?.id,
          "expired"
        );
      }
      setCompanyData(userData?.attributes?.company);
      setInterviewDetails(interviewDetails);
      setUserData(userData);
      setLoader(false);
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    checkInvitation(id);
  }, []);

  return (
    <> 
      {loader ? (
        <Loader />
      ) : (
        <>
          {expiredCode == 0 ? (
            <div className="container mt-5">
                <div className="orion-logo">
                    <img
                      src={
                        companyData?.data?.attributes?.Logo?.data?.attributes
                          ? process.env.REACT_APP_VIDEO_URL +
                            companyData?.data?.attributes?.Logo?.data
                              ?.attributes?.url
                          : orionLogo
                      }                                                                                                                                                                         
                      width="150"
                      height="auto"
                    />
                  </div>
              <div className="row ">
                <div className="col-md-12">
              
                </div>
              </div>
              <div className="row mt-5 pt-5 welcome landing_page">
                <div className=" col-md-5 intro intro_bubble">
                
                  <div className="fs-3 mb-4 text-secondary fw-medium">
                    Hey {userData?.attributes?.Name},
                  </div>
                  <div className="mb-5 text_black fw-semibold lh-base intro-text">
                    <h1>
                      {" "}
                      Welcome to
                      <br />
                      {companyData?.data?.attributes?.Name} for
                      <span className="text_orange">
                        {" "}
                        {interviewDetails?.attributes?.Title} Round
                      </span>
                    </h1>
                  </div>
                  <div className="col-md-12 d-flex gap-5 mt-5 p-0 duration_text">
                    <div className="fs-4 fw-medium ">
                      Test duration
                      <div>
                        <span className="fs-3 fw-medium blue_duration">
                          10 - 15 mins
                        </span>
                      </div>
                    </div>
                    <div className="fs-4 fw-medium ">
                      No. of questions
                      <div>
                        <span className="fs-3 fw-medium blue_duration">
                          {interviewDetails?.attributes?.Questions?.length}{" "}
                          questions
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 intro right_bubble ">
                  <div className="d-flex align-items-center flex-wrap gen-info">
                    <div className="listing">
                      <div className=" intro-text fs-1 fw-semibold">
                        General Instructions:
                      </div>

                      <ol className="ps-3 list-styled-number fs-4">
                        {interviewDetails?.attributes?.Instructions?.map(
                          (item, index) => {
                            return <li key={index}>{item?.Instruction}</li>;
                          }
                        )}
                      </ol>
                      <div className="d-flex ">
                        <button
                          className="continue_btn"
                          onClick={() =>
                            navigate(`/test/${id}`, { state: userData })
                          }
                        >
                          Let's Start
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ErrorPage children={errorStatus[expiredCode]} />
          )}
        </>
      )}
    </>
  );
};

export default WelcomePage;
